import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { projectList } from '@/modules/projectList';
import project from '@/modules/shared/store/project';

export function createProjectDetailsRoutes(): RouteRecordRaw[] {
  return [
    {
      name: 'general',
      path: 'general',
      component: () => import(/* webpackChunkName: "ProjectDetails" */ '../pages/ProjectDetails.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('projectList')) {
          store.registerModule('projectList', projectList);
        }
        next();
      },
    },
  ];
}
