<template>
  <Modal
    :size="pageIndex === 0 ? 'large' : 'medium'"
    :title="t('video.subtitle.upload.title')"
    class="caption-upload-modal"
    :primaryButtonText="primaryButtonText"
    :isDisabledPrimaryButton="!isEnableSave"
    :isSaving="uploadCaptionState.status === 'saving'"
    :onClickPrimaryButton="onClickPrimaryButton"
    :secondaryButtonText="t('common:button.back')"
    :onClickSecondaryButton="onClickSecondaryButton"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <!-- upload files -->
      <div v-if="pageName[pageIndex] === 'upload'" class="video-uploader-container">
        <div
          class="upload-file-container"
          ref="uploaderRef"
          :class="{ 'is-dragging': isDragging, smaller: filesData.length }"
        >
          <div class="upload-file">
            <input
              type="file"
              class="input-file"
              accept=".srt,.vtt"
              multiple
              @change="onSelectFile"
            />
            <i class="fas fa-cloud-upload-alt"></i>
            <p v-if="!isDragging" class="upload-placeholder">
              {{ t('video.subtitle.upload.placeholder') }}
            </p>
            <p v-else class="upload-placeholder">
              {{ t('video.uploader.dropToUpload') }}
            </p>
          </div>
        </div>

        <DataTable v-if="filesData.length">
          <template v-slot:table-header>
            <Row>
              <Column width="200px">
                <HeaderCell :text="t('glossary:file')" />
              </Column>
              <Column>
                <HeaderCell :text="t('video.subtitle.language.title')" />
              </Column>
              <Column>
                <HeaderCell text="Display" />
              </Column>
              <Column width="50px" />
            </Row>
          </template>
          <template v-slot:table-content>
            <Row v-for="(data, dataIndex) in filesData" :key="data.file.name" class="row">
              <Column width="200px">
                <TextCell :text="data.file.name" />
              </Column>
              <Column v-if="data.fileError">
                <div class="error-message">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{data.fileError.message}}
                </div>
              </Column>
              <Column v-if="!data.fileError">
                <LanguageSelector
                  @selected="(value) => onSelectLanguageCode(dataIndex, value)"
                  :value="data.languageCode"
                  :languageCode="data.languageCode"
                  :openDirection="filesData.length >= 3 ? 'top' : 'bottom'"
                  :placeholder="t('video.subtitle.language.select')"
                />
              </Column>
              <Column v-if="!data.fileError">
                <Input
                  type="text"
                  class="form-control"
                  v-model="data.customName"
                  @keydown.enter="onSubmit"
                  :placeholder="t('video.subtitle.displayName.placeholder')"
                />
              </Column>
              <Column width="50px">
                <Button
                  type="button"
                  buttonStyle="text-secondary"
                  buttonClasses="btn-action"
                  @click="onRemoveFile(data)"
                >
                  <template v-slot:icon-prefix>
                    <i class="fas fa-trash-alt trash-icon"></i>
                  </template>
                </Button>
              </Column>
            </Row>
          </template>
        </DataTable>
      </div>

      <!-- select status -->
      <div v-if="pageName[pageIndex] === 'status'">
        <CaptionStatusRadioGroup
          :modelValue="selectedStatusOption"
          :onChange="onStatusOptionChange"
        />
      </div>
    </template>
    <template v-slot:secondary-btn-prefix>
      <i class="fas fa-chevron-left"></i>
    </template>
    <template v-slot:footer>
      <Button @click="onClose" type="button" buttonStyle="text-secondary" v-if="uploadCaptionState.status !== 'error'">
        {{t('common:button.cancel')}}
      </Button>
      <div v-if="uploadCaptionState.status === 'error'" class="error-message">
        {{uploadCaptionState.error?.message}}
      </div>
    </template>
  </Modal>
</template>

<script setup>
import _ from 'lodash';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { uploadCaptionDraft } from '@/modules/videoCaption/services';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import CaptionStatusRadioGroup from '@/modules/captionProfile/components/molecules/captionStatusRadioGroup/CaptionStatusRadioGroup.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { useStore } from '../../../store/hooks';
import LanguageSelector from '../../molecules/languageSelector/LanguageSelector.vue';

const props = defineProps({
  onClose: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits(['toInit']);

const store = useStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const filter = computed(() => route.query);

const filesData = ref([]);
const isDragging = ref(false);
const isEnableSave = ref(false);
const uploaderRef = ref(null);
const uploadCaptionState = ref(setStateSavingStatusByType());

const currentVideo = computed(() => store.state.video.currentVideo);

// publishing status
const selectedStatusOption = ref('draft');
const onStatusOptionChange = (value) => {
  selectedStatusOption.value = value;
};

const onSelectFile = (e) => {
  // First, read files from web browser's file selecting modal.
  let chosenFiles = e.target.files;

  // If there's no files, read files from dragging event.
  if (!chosenFiles?.length) {
    chosenFiles = e.dataTransfer.files;
  }

  // If there's still no files, exit.
  if (!chosenFiles?.length) {
    return;
  }

  chosenFiles.forEach((file) => {
    let fileError;
    if (file.name.split('.').at(-1) !== 'srt' && file.name.split('.').at(-1) !== 'vtt') {
      fileError = {
        message: t('video.subtitle.upload.error.fileType'),
      };
    } else if (file.size === 0) {
      fileError = {
        message: t('video.subtitle.upload.error.emptyFile'),
      };
    }

    filesData.value = [
      ...filesData.value,
      {
        customName: '',
        languageCode: '',
        file,
        fileError,
      },
    ];
  });

  // Reset the target value so that the same file can be selected after deleting
  e.target.value = null;
};

const onRemoveFile = (data) => {
  filesData.value = filesData.value.filter((fileData) => fileData !== data);
};

const onSelectLanguageCode = (dataIndex, value) => {
  filesData.value[dataIndex].languageCode = value.code6392T;
  if (!filesData.value[dataIndex].customName) {
    filesData.value[dataIndex].customName = value.name;
  }
};

const onSubmit = async () => {
  if (!isEnableSave.value) {
    return;
  }

  uploadCaptionState.value = setStateSavingStatusByType({ type: 'saving' });

  isEnableSave.value = false;

  const promises = [];
  const videoKey = currentVideo.value?.key;
  const revisionKey = currentVideo.value.vod?.revisionKey;

  if (!videoKey || !revisionKey || !selectedStatusOption.value) {
    return;
  }

  filesData.value.forEach((data) => {
    if (!data.fileError) {
      const requestPayload = {
        videoKey,
        revisionKey,
        languageCode: data.languageCode,
        file: data.file,
        customName: data.customName,
        publishStatusSetting: selectedStatusOption.value,
      };

      const promise = new Promise((resolve, reject) => {
        uploadCaptionDraft(requestPayload)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });

      promises.push(promise);
    }
  });

  await Promise.all(promises)
    .then(async () => {
      // reload
      store.dispatch('reloadCaptionList', {
        ...(filter.value || {}),
        videoKey: currentVideo.value?.key,
      });

      // go to last page if not
      const { lastPage } = store.state.videoCaption;
      if (Number(filter.value.page) !== Number(lastPage)) {
        // console.log('change', filter.value.page, lastPage);
        router.push({ path: route.path, query: { page: lastPage } });
      }
      uploadCaptionState.value = setStateSavingStatusByType({ type: 'success' });
      props.onClose();
    })
    .catch((err) => {
      const { data, status } = err.response;
      uploadCaptionState.value = setStateSavingStatusByType({ type: 'error', error: formatErrorObject(err, 'Caption', { [status]: data.message }) });
    });
};

// modal state
const pageName = ['upload', 'status'];
const pageIndex = ref(0);
const lastPageIndex = ref(pageName.length - 1);

const onNextPage = () => {
  if (pageIndex.value !== lastPageIndex.value) {
    pageIndex.value += 1;
  }
};
const onBack = () => {
  if (pageIndex.value === 0) {
    emit('toInit');
  } else {
    pageIndex.value -= 1;
  }
};

const onClickPrimaryButton = () => {
  if (pageIndex.value === lastPageIndex.value) {
    // last page
    // final action
    return onSubmit();
  }
  // go to next page

  return onNextPage();
};
const primaryButtonText = computed(() => {
  if (pageIndex.value === lastPageIndex.value) {
    return t('video.subtitle.upload.button');
  }
  return t('common:button.next');
});
const onClickSecondaryButton = () => {
  if (pageIndex.value === 0) {
    emit('toInit');
  } else {
    onBack();
  }
};

watch(
  () => filesData.value,
  () => {
    // .every returns true on empty arr. needs to check if empty
    isEnableSave.value
      = !_.isEmpty(filesData.value)
      // allow save even if some files have errors
      // but submit only correct files with customName and languageCode fields
      && filesData.value.every((data) => (!data.fileError && data.customName && data.languageCode) || data.fileError)
      && !filesData.value.every((data) => data.fileError);
  },
  { deep: true },
);

onMounted(async () => {
  const createEventListener = (event) => {
    event.preventDefault();
    if (event.type === 'dragover') {
      isDragging.value = true;
    } else if (event.type === 'drop') {
      isDragging.value = false;
      onSelectFile(event);
    } else {
      isDragging.value = false;
    }
  };

  if (!uploaderRef.value) {
    return;
  }

  uploaderRef.value.addEventListener('drop', createEventListener);
  uploaderRef.value.addEventListener('dragover', createEventListener);
  uploaderRef.value.addEventListener('dragend', createEventListener);
  uploaderRef.value.addEventListener('dragleave', createEventListener);
});

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/themes/helpers';
@import '~@/assets/scss/breakpoint';

:deep(.data-table-container), :deep(.table) {
  overflow: unset;
}

:deep(.modal-content) {
  min-height: 545px;
}

.has-scroll-bar :deep(.modal-body) {
  position: relative;
}

:deep(.column) {
  overflow: unset;
}

.caption-upload-modal {
  :deep(.list-header-container) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fbfbfc;
  }

  .list-container {
    .item-row {
      > *:not(:last-child) {
        padding-right: 8px;
      }
    }

    .list-item-filename {
      flex: 1 1 100%;
      min-width: 0;
      word-break: break-word;
    }

    .list-item-input {
      flex: 0 0 30%;
    }

    .list-item-action {
      flex: 0 0 48px;
      padding-left: 0;
    }
  }

  :deep(.list-body-container) {
    background-color: #fbfbfc;

    .list-item.list-item-filename {
      color: #343a40;
      font-weight: $font-weight-base;
    }

    :deep(.btn-action) {
      background-color: #e8eaee;
      color: #6c757d;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.form-control {
  margin-bottom: 0;
  /* min-height: 36px; */
}

.dragging-overlay-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.7);

  z-index: 1051;
  pointer-events: none;

  .dragging-overlay {
    display: flex;
    color: white;

    .title {
      font-size: $font-size-base * 2.5;
      color: white;
    }

    .icon {
      color: white;
    }
  }
}
.video-uploader-container {
  height: 356px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-24;
}

.file-table{
  margin-bottom: $spacing-24;
}
.upload-file-container {
  // height: 370px;
  width: 100%;
  margin-bottom: $spacing-32;
  flex-grow: 1;
  min-height: 140px;
  //transition: height 180ms cubic-bezier(0, 0.32, 0.01, 0.99);

  &.smaller {
    height: 124px;

    .upload-file .fas {
      font-size: 32px;
    }
  }

  .upload-file {
    border-radius: 4px;
    border: 2px dashed $grey-400;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    transition: all 200ms ease-out;

    .fas {
      font-size: 46px;
      color: #9ea4a9;
      margin-bottom: 8px;
    }

    .upload-placeholder {
      color: #9ea4a9;
      font-size: 14px;
      margin-bottom: 0;
      white-space: break-spaces;
      text-align: center;
    }

    &:hover {
      border-color: $grey-600;
      background-color: $grey-200;
    }
  }

  &.is-dragging {
    .upload-file {
      border-color: $grey-600;
      background-color: $grey-200;
    }
  }
}

.file-list-container {
  @extend .scrollbar;
  margin-top: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 30;
  cursor: pointer;
  width: 100%;
}

.action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
}

.btn-light {
  color: #6c757d;
}

.trash-icon {
  font-size: $spacing-12;
}

@media screen and (max-width: $max-layout-sm) {
  .item-row {
    flex-direction: column;

    > * {
      flex: 0 0 100% !important;
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .list-item-filename {
    font-size: 14px;
    color: $grey-800;
  }

  .upload-file {
    padding: $spacing-base * 2;
  }
}

.error-message {
  color: $danger;
  display: flex;
  align-items: center;
  gap: $spacing-4;
}

.row{
  background-color: $white;
}
</style>
