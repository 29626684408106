import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { GlobalState } from '@/types/Global.type';
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { ProjectListState } from '@/modules/projectList/types/projectList.type';

export const useStore = () => baseUseStore<{
  global: GlobalState;
  project: ProjectState;
  projectList: ProjectListState;
}>(storeKey);
