<template>
  <Modal
    :title="t('project.moveVideos.title')"
    @modalClose="onClose"
    size="small"
    :primaryButtonText="t(confirmButtonKey, { count: videoCount })"
    :onClickPrimaryButton="onConfirmMoveVideos"
    primaryButtonStyle="warning"
    :isDisabledPrimaryButton
  >
    <template v-slot:body>
      <div class="content-container">
        <div>
          <p class="select-label">{{ t('project.moveVideos.modal.moveTo') }}</p>
          <SelectOption
            :disabled="loadProjectListState.status === 'loading'"
            :placeholder="t('project.moveVideos.modal.placeholder')"
            :options
            v-model="selectedProject"
            searchable
          />
        </div>
        <p
          class="description"
          v-html="t(description1Key, { count: videoCount, projectName, destinationProjectName: selectedProject?.name })"
        />
        <p class="description" v-html="t('project.moveVideos.modal.description2')" />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { moveVideos } from '@/modules/projectDetails/services';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { getQueryAsString } from '@/modules/shared/utils/query';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { StatusState } from '@/types/State.type';
import isEmpty from 'lodash/isEmpty';
import {
  computed, onMounted, ref, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import type { Project } from '@/modules/shared/types/project.type';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '../../../store/hooks';

interface MoveVideosModalProps {
  onClose: () => void;
}

const props = defineProps<MoveVideosModalProps>();
const { onClose } = toRefs(props);

const store = useStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const selectedProject = ref<Project | null>(null);
const moveVideosState = ref<StatusState>(setStateLoadingStatusByType());

const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
const projectName = computed(() => store.state.project.currentProject?.name ?? '');
const projectKey = computed(() => store.state.project.currentProject?.key ?? '');
const projectList = computed(() => store.state.projectList.projectList ?? []);
const options = computed(() => projectList.value?.filter((prj) => prj.key !== projectKey.value));
const loadProjectListState = computed(() => store.state.projectList.loadProjectListState);

const description1Key = computed(() => {
  if (selectedProject.value) {
    return `project.moveVideos.modal.description1Selected${videoCount.value > 1 ? '_plural' : ''}`;
  }

  return `project.moveVideos.modal.description1${videoCount.value > 1 ? '_plural' : ''}`;
});

const confirmButtonKey = computed(() => (
  `project.moveVideos.modal.button${videoCount.value > 1 ? '_plural' : ''}`
));

const isDisabledPrimaryButton = computed(() => (
  loadProjectListState.value.status === 'loading' || !selectedProject.value || moveVideosState.value.status === 'loading'
));

async function onConfirmMoveVideos() {
  if (!selectedProject.value || !projectKey.value) {
    return;
  }

  const destinationProjectKey = selectedProject.value.key;
  moveVideosState.value = setStateLoadingStatusByType({ type: 'loading' });

  try {
    await moveVideos({
      sourceProjectKey: projectKey.value,
      destinationProjectKey,
    });

    store.commit('setAreProjectVideosMoved', true);
    setTimeout(() => store.commit('setAreProjectVideosMoved', false), 5000);

    moveVideosState.value = setStateLoadingStatusByType({ type: 'success' });
    await router.push(`/${teamName.value}/projects/${destinationProjectKey}`);
  } catch (error) {
    console.error(error);
    moveVideosState.value = setStateLoadingStatusByType({
      type: 'error',
      error: formatErrorObject(error, 'Move Videos'),
    });
  }
}

onMounted(() => {
  if (isEmpty(projectList.value)) {
    store.dispatch('loadProjectList');
  }
});
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';

:deep(.multiselect) {
  max-width: 100%;
}

.select-label {
  margin-bottom: $spacing-4;
}

.description {
  :deep(strong) {
    font-weight: 500;
  }
}

.content-container {
  display: flex;
  flex-flow: column;
  gap: 24px;
}
</style>
