<template>
  <router-link v-if="!responsive" :to="videoURL">
    <Row vertical-align="center">
      <Column width="140px">
        <div class="video-cover-image">
          <ImageView
            :source="getVideoUploadCoverImage({ video, defaultCoverImage, currentUser })"
            imageSize="small"
          />
          <div class="video-duration" v-if="isVideoDurationAvailable(video)">
            {{ getVideoDuration(video) }}
          </div>
          <VideoItemStatusV2 :video="video" />
        </div>
      </Column>
      <Column minWidth="200px" isPrimaryColumn>
        <div class="video-info">
          <span class="video-title">{{ video?.title }}</span>
          <div class="video-badge">
            <Badge
              :title="getVideoDeliveryModelTitle(video.vod.deliveryModel)"
              size="small"
            />
          </div>
          <div class="video-upload-progress" v-if="isVideoUploading(video)">
            <ProgressRing
              :radius="6"
              :stroke="2"
              strokeColor="#2377CD"
              backgroundStrokeColor="#B1B6BE"
              :progress="getVideoUploadProgress(video)"
            />
            {{ getVideoUploadProgress(video) }}% Uploaded
          </div>
        </div>
      </Column>
      <Column minWidth="130px">
        <div :class="{ hideTooltip: !isVideoKeyCopied, copy: true }" class="copy-video-key">
          <span style="margin-top: -1px" class="video-key" data-test="video-key"> {{ video.key }}</span>
          <Tooltip class="disable-click-row">
            <template v-slot:tooltip-text>{{ isVideoKeyCopied ? 'Copied' : 'Copy' }} </template>
            <Button
              @click.prevent="copyVideoKey(video.key)"
              buttonClasses="copyBtn"
              buttonStyle="text-secondary"
              isSquare
            >
              <template v-slot:icon-prefix>
                <i class="fal fa-clone icon"></i>
              </template>
            </Button>
          </Tooltip>
        </div>
      </Column>
      <Column minWidth="180px">
        <div class="geoblock-status">
          <div class="geoblock-status-item" v-if="geoblockPolicyRuleId">
            <i class="fas fa-earth-asia"></i>
            <span>{{ video.geoblockPolicy.name }}</span>
          </div>
          <div
            class="geoblock-status-item"
            v-else-if="customRules.allowCountryCodes.length || customRules.denyCountryCodes.length"
          >
            <span class="allow-container">
              <i class="fas fa-earth-asia"></i>
              <span v-if="customRules.allowCountryCodes.length">
                <span class="title">Allowed:</span>
                <span class="country">
                  {{ allowCountries }}
                </span>
              </span>
            </span>
            <span v-if="customRules.denyCountryCodes.length">
              <span class="title">Blocked:</span>
              <span class="country">
                {{ denyCountries }}
              </span>
            </span>
          </div>
          <div class="geoblock-status-item" v-else-if="isAllowAllCountries">
            <i class="fas fa-circle-check"></i>
            <span>{{ $t('video.geoBlock.allowAll') }}</span>
          </div>
        </div>
      </Column>
      <Column minWidth="120px">
        <TextCell
          className="video-created-at"
          dataTest="video-created-at"
          :text="formatVideoDateTime(video?.updatedAt)"
        />
      </Column>
      <Column width="140px" isActionColumn>
        <div class="action-container">
          <Tooltip v-if="getVideoURL(video)" class="copy-url-button-container">
            <template v-slot:tooltip-text> Copy Video URL </template>
            <Button
              @click.prevent="copyVideoURL(getVideoURL(video))"
              type="button"
              buttonStyle="text-secondary"
            >
              <template v-slot:icon-prefix>
                <i v-if="isVideoURLCopied" class="fas fa-check"></i>
                <i v-else class="fas fa-link"></i>
              </template>
            </Button>
          </Tooltip>
          <Tooltip v-if="getEmbeddedCode(video)" class="copy-embed-button-container">
            <template v-slot:tooltip-text> Copy Embed Code </template>
            <Button
              @click.prevent="copyEmbed(getEmbeddedCode(video))"
              type="button"
              buttonStyle="text-secondary"
              dataTest="copy-embed-button"
            >
              <template v-slot:icon-prefix>
                <i v-if="isEmbedCopied" class="fas fa-check"></i>
                <i v-else class="fas fa-code"></i>
              </template>
            </Button>
          </Tooltip>
        </div>
      </Column>
    </Row>
  </router-link>

  <!-- responsive -->
  <div
    v-if="responsive"
    @click="$router.push(videoURL)"
    class="video-item-mobile"
  >
    <div class="video-cover-image">
      <ImageView :source="getVideoUploadCoverImage({ video, defaultCoverImage, currentUser })" imageSize="small" />
      <div class="video-duration" v-if="isVideoDurationAvailable(video)">
        {{ getVideoDuration(video) }}
      </div>
      <VideoItemStatusV2 :video="video" />
    </div>
    <div class="video-info">
      <div class="video-title-container">
        <div>
          <div class="video-title">{{ video?.title }}</div>
          <div class="video-upload-progress" v-if="isVideoUploading(video)">
            <ProgressRing
              :radius="6"
              :stroke="2"
              strokeColor="#2377CD"
              backgroundStrokeColor="#B1B6BE"
              :progress="getVideoUploadProgress(video)"
            />
            {{ getVideoUploadProgress(video) }}% Uploaded
          </div>
          <div class="video-updated" v-else>
            {{ formatVideoDateTime(video?.createdAt) }}
            <!-- v-if="video.vod.deliveryModel" -->
            <div class="devlivery-model-badge-responsive">
              <Badge
                :title="getVideoDeliveryModelTitle(video.vod.deliveryModel)"
                size="small"
              />
            </div>
          </div>
        </div>
        <Dropdown iconClassName="fas fa-ellipsis-vertical" @click.stop="() => {}">
          <DropdownItem :onClick="() => copyVideoURL(getVideoURL(video))">
            <i v-if="isVideoURLCopied" class="fas fa-check"></i>
            <i v-else class="fas fa-link" />
            Copy Video URL
          </DropdownItem>
          <DropdownItem :onClick="() => copyEmbed(getEmbeddedCode(video))">
            <i v-if="isEmbedCopied" class="fas fa-check"></i>
            <i v-else class="fas fa-code" />
            Copy Embed Code
          </DropdownItem>
          <DropdownItem :to="videoURL">
            <i class="fas fa-circle-info" />
            Video Details
          </DropdownItem>
        </Dropdown>
      </div>
      <div class="geoblock-status">
        <div class="geoblock-status-item" v-if="isAllowAllCountries">
          <i class="fas fa-circle-check"></i>
          <span>{{ $t('video.geoBlock.allowAll') }}</span>
        </div>
        <div class="geoblock-status-item" v-else-if="geoblockPolicyRuleId">
          <i class="fas fa-earth-asia"></i>
          <span>{{ video.geoblockPolicy.name }}</span>
        </div>
        <div
          class="geoblock-status-item"
          v-else-if="customRules.allowCountryCodes.length || customRules.denyCountryCodes.length"
        >
          <span v-if="customRules.allowCountryCodes.length" class="allow-container">
            <i class="fas fa-earth-asia"></i>
            <span>
              <span class="title">Allowed in</span>
              <span class="country">
                {{ customRules.allowCountryCodes.length }} countries
              </span>
            </span>
          </span>
          <span v-else-if="customRules.denyCountryCodes.length">
            <i class="fas fa-earth-asia"></i>
            <span class="title">Blocked in</span>
            <span class="country">
              {{ customRules.denyCountryCodes.length }} countries
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
// import { goToPageDetail } from '@/modules/shared/utils/routerHistory';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import ProgressRing from '@/modules/shared/components/atoms/progressRing/ProgressRing.vue';
import Dropdown from '@/modules/shared/components/atoms/dropdown/Dropdown.vue';
import {
  getVideoCreatorName,
  getVideoDuration,
  getVideoUploadingInfo,
  getVideoUploadingInfoState,
  getVideoProcessingInfo,
  getVideoProcessingInfoState,
  getVideoUploadProgress,
  getVideoUploadCoverImage,
  isVideoProcessing,
  isVideoSourceWaiting,
  isVideoUploadQueued,
  isVideoUploading,
  isVideoUploadByOther,
  isVideoUploadingByOther,
  isVideoUploadRetry,
  isVideoUploadFailed,
  getVideoDeliveryModelTitle,
} from '@/modules/videoDetail/utils/videoManagement';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import DropdownItem from '@/modules/shared/components/atoms/dropdownItem/DropdownItem.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { copyValue } from '@/utils/copy';
import VideoItemStatusV2 from '../../atoms/videoItemStatusV2/VideoItemStatusV2.vue';

export default {
  props: {
    video: {
      type: Object,
      required: true,
    },
    columnType: {
      type: String,
      required: false,
      default: () => 'created',
    },
    responsive: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  components: {
    Tooltip,
    ImageView,
    VideoItemStatusV2,
    Button,
    Row,
    Column,
    TextCell,
    ProgressRing,
    Dropdown,
    DropdownItem,
    Badge,
  },
  data() {
    return {
      isDropdownMenuShowing: false,
      fill: { color: '#2EA2E5' },
      defaultCoverImage: 'https://i.imgur.com/NhBmeTI.png',
      isDeleteVideoModalShowing: false,
      isVideoURLCopied: false,
      isVideoKeyCopied: false,
      isEmbedCopied: false,
      videoObject: {},
    };
  },
  computed: {
    ...mapState({
      currentProject: (state) => state.project.currentProject,
      currentUser: (state) => state.user.currentUser,
      countryList: (state) => state.geoblockRuleList.countryList,
    }),
    teamName() {
      return this.$route.params.teamName;
    },
    projectKey() {
      return this.$route.params.projectKey;
    },
    geoblockPolicyRuleId() {
      return this.video.geoblockPolicy?.ruleId;
    },
    customRules() {
      return this.video.geoblockPolicy?.appliedRule;
    },
    isAllowAllCountries() {
      const allowCountryCodes = _.get(this.customRules, 'allowCountryCodes', []);
      const denyCountryCodes = _.get(this.customRules, 'denyCountryCodes', []);
      return allowCountryCodes.length === 0 && denyCountryCodes.length === 0;
    },
    allowCountries() {
      const allowCountryCodes = _.get(this.customRules, 'allowCountryCodes', []);
      if (allowCountryCodes.length) {
        return allowCountryCodes.map((country) => this.getCountryFullName(country)).join(', ');
      }
      return null;
    },
    denyCountries() {
      const denyCountryCodes = _.get(this.customRules, 'denyCountryCodes', []);
      if (denyCountryCodes.length) {
        return denyCountryCodes.map((country) => this.getCountryFullName(country)).join(', ');
      }
      return null;
    },
    videoURL() {
      const baseURL = `/${this.teamName}/projects`;
      if (this.projectKey) {
        return `${baseURL}/${this.projectKey}/videos/${this.video?.key}/information`;
      }
      if (this.video?.project?.key) {
        return `${baseURL}/${this.video?.project?.key}/videos/${this.video?.key}/information?from=all`;
      }
      return '';
    },
  },
  methods: {
    formatVideoDateTime,
    getVideoCreatorName,
    getVideoDuration,
    getVideoUploadingInfo,
    getVideoUploadingInfoState,
    getVideoProcessingInfo,
    getVideoProcessingInfoState,
    getVideoUploadProgress,
    getVideoUploadCoverImage,
    isVideoProcessing,
    isVideoSourceWaiting,
    isVideoUploadQueued,
    isVideoUploading,
    isVideoUploadByOther,
    isVideoUploadingByOther,
    isVideoUploadRetry,
    isVideoUploadFailed,
    getVideoDeliveryModelTitle,
    async onClickDeleteButton(video) {
      this.videoObject = {
        title: video.title,
        key: video.key,
      };
      this.isDeleteVideoModalShowing = true;
    },
    onCloseDeleteModal() {
      this.videoObject = _.cloneDeep({});
      this.isDeleteVideoModalShowing = false;
    },
    cutLongName(name) {
      if (name.length > 20) {
        return `${name.substring(0, 20)} ...`;
      }
      return name;
    },
    isVideoDurationAvailable(video) {
      return _.get(video, 'duration', null);
    },
    getVideoURL(video) {
      if (_.get(video, 'primaryPlaybackUrl.hls', null)) {
        return _.get(video, 'primaryPlaybackUrl.hls[0].url', '');
      }
      return _.get(video, 'primaryPlaybackUrl.mp4Playlist[0].url', '');
    },
    isVideoPlayerActive() {
      const player = _.get(this.currentProject, 'playerId', null);
      return !_.isEmpty(player);
    },
    getEmbeddedCode(video) {
      const embeddedUrl = this.getVideoPlayerUrl(video);
      if (embeddedUrl) {
        return `<iframe src="${embeddedUrl}" width="560" height="320" frameborder="0" allowfullscreen></iframe>`;
      }
      return '';
    },
    getVideoPlayerUrl(video) {
      return _.get(video, 'embeddedUrl', '');
    },
    copyVideoURL(value) {
      const hiddenField = document.createElement('textarea');
      document.body.appendChild(hiddenField);
      hiddenField.value = value;
      hiddenField.select();
      document.execCommand('copy');
      document.body.removeChild(hiddenField);
      this.isVideoURLCopied = true;
      const timeout = setTimeout(() => {
        this.isVideoURLCopied = false;
        clearTimeout(timeout);
      }, 1000);
    },
    copyVideoKey(value) {
      copyValue(value);
      this.isVideoKeyCopied = true;
      const timeout = setTimeout(() => {
        this.isVideoKeyCopied = false;
        clearTimeout(timeout);
      }, 1000);
    },
    copyEmbed(value) {
      copyValue(value);
      this.isEmbedCopied = true;
      const timeout = setTimeout(() => {
        this.isEmbedCopied = false;
        clearTimeout(timeout);
      }, 1000);
    },
    getCountryFullName(countryCode) {
      const country = _.find(this.countryList, { countryCode });
      return country ? country.countryNameEN : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.copy-video-key {
  display: flex;
  align-items: center;
  gap: $spacing-8;

  .hideTooltip {
    :deep(.tooltip .popper) {
      display: none;
    }
  }

  :deep(.button) {
    width: 12px;
    height: 12px;
    padding: 0;
  }
}

.geoblock-status-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  i {
    margin-right: $spacing-4;
  }

  .title {
    margin-right: 2px;
  }

  .block-container,
  .allow-container {
    word-break: break-all;
  }

  > .country {
    &:not(:nth-child(1))::before {
      content: ', ';
    }
  }
}

.video-info {
  display: flex;
  flex-direction: column;
  word-break: break-word;

  .video-upload-progress {
    display: flex;
  }
}

.video-cover-image {
  flex: 0 0 120px;
  margin-right: $spacing-base;
  position: relative;
}

.video-duration {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $grey-200;
  font-size: $font-level-8;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 4px 6px;
}

.action-container {
  display: flex;
  gap: $spacing-24;
}

@media screen and (max-width: $max-layout-md) {
  .video-cover-image {
    position: relative;
  }
}

@media screen and (max-width: $max-layout-sm) {
  .video-cover-image {
    margin-right: $spacing-base * 0.5;
    width: 50%;
  }
}

.video-badge {
  margin-top: $spacing-8;
}

.video-item-mobile {
  display: flex;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 28px;
  line-height: $spacing-base;
  cursor: pointer;

  .video-cover-image{
    width: 120px;
    height: 67.5px;
  }

  .video-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $spacing-12;
  }

  .video-title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .video-title{
      max-height: $spacing-base * 2;
      overflow: hidden;
      margin-bottom: $spacing-4;
    }
  }

  .devlivery-model-badge-responsive {
    margin-top: 0.5rem;
  }
}
</style>
