<template>
  <div :class="['danger-zone-card', color]">
    <h3 class="title">{{ title }}</h3>
    <p class="description" v-html="description" />
    <div class="button-container">
      <Button
        type="button"
        @click="onClickButton"
        :buttonStyle="color"
      >
        <template v-slot:icon-prefix>
          <i :class="buttonIcon" />
        </template>
        {{ buttonText }}
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

interface DangerZoneCardProps {
  title: string;
  description: string;
  color?: 'danger' | 'warning';
  buttonIcon: string;
  buttonText: string;
  onClickButton: () => void;
}

const props = withDefaults(defineProps<DangerZoneCardProps>(), {
  color: 'danger',
});

const {
  title,
  description,
  color,
  buttonIcon,
  buttonText,
  onClickButton,
} = toRefs(props);
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.danger-zone-card {
  border-radius: $border-radius-5;
  border: 1px solid;
  background-color: #FFF;
  padding: $spacing-24 $spacing-32;
  margin-bottom: $spacing-base;

  @media screen and (max-width: $max-layout-sm) {
    padding: $spacing-base;
  }

  &.danger {
    border-color: $danger;
  }

  &.warning {
    border-color: $warning;
  }

  .title {
    font-size: $font-level-4;
    font-weight: 400;
    color: $grey-800;
    margin-bottom: $spacing-24;
  }

  .description {
    color: $grey-600;
    margin-bottom: $spacing-base;
  }
}
</style>
