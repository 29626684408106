import { createRouter, createWebHistory } from 'vue-router';
import posthog from 'posthog-js';
import store from '@/store';
import RootChrome from '@/layouts/RootChrome.vue';
import AppChrome from '@/layouts/AppChrome.vue';
import PageNotFound from '@/layouts/PageNotFound.vue';
import { guardAuth } from '@/middlewares/guard';
import { createPersonalAccessTokenRoutes } from '@/modules/personalAccessToken';
import { videoUpload } from '@/modules/videoUpload';
import project from '@/modules/shared/store/project';
import { createManagementRoutes } from './manage';
import { createRoutes as createProjectRoutes } from './project';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Root',
      component: RootChrome,
      meta: { requiresAuth: true },
      children: [
        {
          path: ':teamName',
          name: 'teamName',
          redirect: { name: 'projects' },
          component: AppChrome,
          beforeEnter: (to, from, next) => {
            if (!store.hasModule('videoUpload')) {
              store.registerModule('videoUpload', videoUpload);
            }
            if (!store.hasModule('project')) {
              store.registerModule('project', project);
            }
            next();
          },
          children: [
            ...createPersonalAccessTokenRoutes(),
            ...createProjectRoutes(),
            createManagementRoutes(),
          ],
        },
        {
          path: ':teamName(.*)*',
          component: PageNotFound,
        },
      ],
    },
  ],
  // eslint-disable-next-line consistent-return
  scrollBehavior(to) {
    const blockScrollTopPageName = ['projectVideos', 'createGeoblockRule', 'editGeoblockRule', 'geoBlocking', 'clearKey'];
    if (!to.hash && !blockScrollTopPageName.includes(to.name)) {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach(guardAuth);

router.beforeResolve((to, from, next) => {
  store.commit('SET_PAGE_LOADING', true);
  next();
});

router.afterEach(() => {
  posthog.capture('$pageview');
  store.commit('SET_PAGE_LOADING', false);
});

export default router;
