import { axiosAPI } from '@/plugins/axios';

interface MoveVideosProps {
  sourceProjectKey: string;
  destinationProjectKey: string;
}

export const moveVideos = ({ sourceProjectKey, destinationProjectKey }: MoveVideosProps) => (
  axiosAPI.patch(`/projects/${sourceProjectKey}/move-entire-videos-to-another-project`, { destinationProjectKey })
);
