<template>
  <button
    v-if="!url"
    :aria-label="ariaLabel"
    class="button"
    :data-test="dataTest"
    :class="[
      buttonStyle || 'primary',
      disabled ? 'disabled' : '',
      isFullWidth ? 'full-width' : '',
      !$slots['content'] && !$slots['default'] ? 'square' : '',
      isSquare ? 'square-padding square' : '',
      size,
      buttonClasses,]"
    :disabled="disabled || isSaving"
    :type="type"
    @click="(event) => emit('click', event)">
    <i class="fa fa-spinner fa-spin" v-if="isSaving" />
    <div
      class="icon-prefix"
      :class="{
        'only-child': !$slots['content'] && !$slots['default'] && !$slots['icon-suffix'],
        'align-icon-center': alignIcon === 'center',
      }"
      v-if="$slots['icon-prefix'] && !isSaving">
      <slot name="icon-prefix"></slot>
    </div>
    <slot name="content"></slot>
    <slot v-if="!$slots['content']"></slot>
    <div
      class="icon-suffix"
      :class="{ 'only-child': !$slots['content'] && !$slots['default'] && !$slots['icon-prefix'] }"
      v-if="$slots['icon-suffix']">
      <slot name="icon-suffix"></slot>
    </div>
  </button>
  <router-link
    v-if="url && !isExternalUrl"
    class="button"
    :class="[
      buttonStyle,
      disabled ? 'disabled' : '',
      isFullWidth ? 'fullWidth' : '',
      !$slots['content'] && !$slots['default'] ? 'square' : '',
      isSquare ? 'square-padding square' : '',
      size,
      buttonClasses,
    ]"
    :disabled="disabled"
    :to="url"
    :data-test="dataTest"
    @click.stop="(event: MouseEvent) => emit('click', event)">
    <i class="fa fa-spinner fa-spin" v-if="isSaving" />
    <div
      class="icon-prefix"
      :class="{ 'only-child': !$slots['content'] && !$slots['default'] && !$slots['icon-suffix'] }"
      v-if="$slots['icon-prefix'] && !isSaving">
      <slot name="icon-prefix"></slot>
    </div>
    <slot name="content"></slot>
    <slot v-if="!$slots['content']"></slot>
    <div
      class="icon-suffix"
      :class="{ 'only-child': !$slots['content'] && !$slots['default'] && !$slots['icon-prefix'] }"
      v-if="$slots['icon-suffix']">
      <slot name="icon-suffix"></slot>
    </div>
  </router-link>
  <a
    v-if="url && isExternalUrl"
    class="button"
    :class="[
      buttonStyle,
      disabled ? 'disabled' : '',
      isFullWidth ? 'fullWidth' : '',
      !$slots['content'] && !$slots['default'] ? 'square' : '',
      isSquare ? 'square-padding square' : '',
      size,
      buttonClasses,
    ]"
    :href="url"
    :disabled="disabled"
    target="_blank"
    rel="noreferer nofollow"
    @click.stop="(event) => emit('click', event)">
    <i class="fa fa-spinner fa-spin" v-if="isSaving" />
    <div
      class="icon-prefix"
      :class="{ 'only-child': !$slots['content'] && !$slots['default'] && !$slots['icon-suffix'] }"
      v-if="$slots['icon-prefix'] && !isSaving">
      <slot name="icon-prefix"></slot>
    </div>
    <slot name="content"></slot>
    <slot v-if="!$slots['content']"></slot>
    <div
      class="icon-suffix"
      :class="{ 'only-child': !$slots['content'] && !$slots['default'] && !$slots['icon-prefix'] }"
      v-if="$slots['icon-suffix']">
      <slot name="icon-suffix"></slot>
    </div>
  </a>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import { ButtonProps } from '@/types/Button.type';

const props = withDefaults(defineProps<ButtonProps>(), {
  buttonStyle: 'primary',
  size: 'default',
  type: 'button',
  dataTest: '',
});

const {
  buttonClasses,
  buttonStyle,
  disabled,
  isExternalUrl,
  isFullWidth,
  isSaving,
  isSquare,
  size,
  type,
  url,
  dataTest,
  alignIcon,
} = toRefs(props);

const emit = defineEmits<{click: [value: MouseEvent]}>();
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.button {
  display: flex;
  flex-direction: row;
  /* align-items: baseline; */
  align-items: baseline;
  justify-content: center;
  padding: $spacing-8 $spacing-base;
  padding-bottom: 7px;
  border-radius: 5px;
  font-weight: $font-weight-base;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid transparent;
  //transition: all 0.2s ease-in-out;

  >.icon-prefix {
    display: flex;
    margin-right: $spacing-8;

    &.only-child {
      margin-right: 0;
    }

    &.align-icon-center {
      margin-block: auto;
    }
  }

  >.icon-suffix {
    display: flex;
    margin-left: $spacing-8;

    &.only-child {
      margin-left: 0;
    }
  }

  &.primary {
    background: $ci-primary;
    color: $white;

    &:hover:not(:disabled) {
      background: #216fc0;
    }

    &:active:not(:disabled),
    &.active {
      background: #216fc0;
      border: 1px solid #dceaf9;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &:disabled {
      background: #92bfec;
      color: #d3e5f8;
      cursor: not-allowed;
    }
  }

  &.secondary {
    background: $grey-200;
    border: 1px solid $grey-200;
    color: $grey-600;

    &:hover:not(:disabled) {
      background: $grey-300;
      border: 1px solid $grey-300;
      color: $grey-700;
    }

    &:active:not(:disabled),
    &.active {
      background: $grey-300;
      color: $grey-700;
      border: 1px solid $grey-200;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &:disabled {
      background: #f7f7f8;
      color: #cdd1d5;
      cursor: not-allowed;
    }
  }

  &.danger {
    background: $danger;
    color: $white;

    &:hover:not(:disabled) {
      background: darken($danger, 10%);
      // -webkit-background-clip: padding-box;
      // background-clip: padding-box;
    }

    &:active:not(:disabled),
    &.active {
      background: #c21f1f;
      color: $white;
      border: 1px solid #f9dcdc;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &:disabled {
      background: #ee9191;
      color: #f8d3d3;
      cursor: not-allowed;
    }
  }

  &.warning {
    background: $warning;
    color: $grey-800;

    &:hover:not(:disabled) {
      background: #ca8c17;
      // -webkit-background-clip: padding-box;
      // background-clip: padding-box;
    }

    &:active:not(:disabled),
    &.active {
      background: #ca8c17;
      color: $grey-800;
      border: 1px solid #fbf0da;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &:disabled {
      background: #f2cf8c;
      color: #faecd1;
      cursor: not-allowed;
    }
  }

  &.text-primary {
    background: transparent;
    color: $ci-primary;

    &:hover:not(:disabled) {
      background: #e9f2fb;
      color: #216fc0;
    }

    &:active:not(:disabled),
    &.active {
      background: #e9f2fb;
      color: #216fc0;
      border: 1px solid #dceaf9;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &:disabled {
      background: transparent;
      color: #92bfec;
      cursor: not-allowed;
    }
  }

  &.text-secondary {
    background: transparent;
    color: $grey-600;

    &:hover:not(:disabled) {
      background: #f1f2f3;
      color: $grey-700;
    }

    &:active:not(:disabled),
    &.active {
      background: $grey-200;
      color: $grey-700;
      border: 1px solid #ebedef;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &:disabled {
      background: transparent;
      color: #cdd1d5;
      cursor: not-allowed;
    }
  }

  &.text-primary-light {
    background: transparent;
    color: $ci-primary-light;

    &:hover:not(:disabled) {
      background: #2b3d50;
    }

    &:active:not(:disabled),
    &.active {
      background: #2b3d50;
      border: 1px solid #30455a;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &:disabled {
      background: transparent;
      color: #004f70;
      cursor: not-allowed;
    }
  }

  &.text-secondary-light {
    background: transparent;
    color: $grey-200;

    &:hover:not(:disabled) {
      background: #2b3d50;
    }

    &:active:not(:disabled),
    &.active {
      background: #2b3d50;
      border: 1px solid #30455a;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &:disabled {
      background: transparent;
      color: #67707b;
      cursor: not-allowed;
    }
  }

  &.link-primary {
    background: transparent;
    padding: 0;
    border: none;
    color: $ci-primary;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      color: #216fc0;
      cursor: pointer;
      text-decoration: underline;

    }

    &:disabled {
      color: #d3e5f8;
      cursor: not-allowed;
    }
  }

  &.link-secondary {
    background: transparent;
    padding: 0;
    border: none;
    color: $grey-600;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      color: $grey-800;
      cursor: pointer;
      //text-decoration: underline;

    }

    &:disabled {
      color: #cdd1d5;
      cursor: not-allowed;
    }
  }

  &.link-light {
    background: transparent;
    padding: 0;
    border: none;
    color: $grey-200;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      color: $grey-200;
      cursor: pointer;
      text-decoration: underline;

    }

    &:disabled {
      color: $grey-600;
      cursor: not-allowed;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.square {
    padding: 0;
    width: 33px;
    height: 33px;
    padding: $spacing-8;
  }

  &.square-padding {
    padding: $spacing-8;
  }

  &.large {
    font-size: 1.125rem;
  }

  &.small {
    padding: $spacing-4 $spacing-8;
    font-size: $font-level-8;
    > .icon-prefix {
      margin-right: $spacing-4;
    }
  }

  &.btn-secondary-outline {
    border: 1px solid $grey-300;
    background: $grey-200;
    color: $grey-600;
  }
}

.fa-spinner {
  margin-right: $spacing-8;
}
</style>
