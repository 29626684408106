import _ from 'lodash';
import i18n from '@/plugins/lang/i18n';
import {
  transformSecondsToDuration,
} from '@/modules/shared/utils/dateFormatter';
import type { Nullable } from '@/types/index.type';
import type { Video } from '@/modules/shared/types/video.type';
import type { User } from '@/modules/user';
import type { Project } from '@/modules/shared/types/project.type';
import type { VideoUploadByKeyObject } from '@/modules/videoUpload/types/videoUpload.type';
import type { Collection } from '@/modules/collection/types';
import type { VodDeliveryModel } from '@/modules/preset';
import { isVideoUploadByKeyObject } from '@/modules/shared/utils/typeGuard';
import type { VideoJobTrack } from '../types/videoJob.type';

const errorCodeForFileProblemCase = [1, 2];

type VideoOrVideoUploadObject = VideoUploadByKeyObject | Video | null | undefined;

const getVideoCreatorName = (video: VideoOrVideoUploadObject) => _.get(video, 'creator.name', null);

const getVideoDuration = (video: VideoUploadByKeyObject | undefined | null): string => {
  const duration = _.get(video, 'duration', null);
  if (duration) {
    return transformSecondsToDuration(duration);
  }

  return i18n.global.t('common:form.empty');
};

const getVideoUploadingInfo = (video: VideoOrVideoUploadObject) => _.get(video, 'uploadingInfo', null);

const getVideoUploadingInfoState = (video: VideoOrVideoUploadObject) => _.get(video, 'uploadingInfo.state', null);

const getVideoUploadProgress = (video: VideoUploadByKeyObject | null | undefined) => _.get(video, 'uploadingInfo.progress', 0);

const getVideoProcessingInfo = (video: VideoOrVideoUploadObject) => _.get(video, 'vod.processingInfo', null);

const getVideoProcessingInfoState = (video: VideoOrVideoUploadObject) => _.get(video, 'vod.processingInfo.state', null);

const getVideoTotalExpectedOutputs = (video: VideoOrVideoUploadObject) => _.get(video, 'vod.processingInfo.totalExpectedOutputs', 0);

const getVideoTotalCompletedOutputs = (video: VideoOrVideoUploadObject) => _.get(video, 'vod.processingInfo.totalCompletedOutputs', 0);

const getVideoProbeErrorType = (video: VideoUploadByKeyObject) => _.get(video, 'vod.source.probeErrorType', 'not found');

const isVideoProbeNotComplete = (video: VideoUploadByKeyObject) => {
  const videoProcessingInfoState = getVideoProcessingInfoState(video);
  if (videoProcessingInfoState) {
    return videoProcessingInfoState === 'sourceWaiting' || videoProcessingInfoState === 'sourceIngression';
  }
  return false;
};

const isVideoProject = (video: VideoUploadByKeyObject, project: Nullable<Project>) => {
  const videoProjectKey = _.get(video, 'project.key', null);
  if (!videoProjectKey) {
    return false;
  }
  return videoProjectKey === project?.key;
};

const isVideoSourceWaiting = (video: VideoOrVideoUploadObject) => {
  const videoProcessingInfoState = getVideoProcessingInfoState(video);
  if (videoProcessingInfoState) {
    return videoProcessingInfoState === 'sourceWaiting';
  }
  return false;
};

const isVideoSourceUploading = (video: VideoUploadByKeyObject) => {
  if (isVideoSourceWaiting(video)) {
    return true;
  }
  const uploadingInfo = _.get(video, 'uploadingInfo', null);
  if (uploadingInfo && uploadingInfo.state === 'uploading') {
    return true;
  }
  return false;
};

const isVideoSourceIngression = (video: VideoUploadByKeyObject) => {
  const videoProcessingInfoState = getVideoProcessingInfoState(video);
  if (videoProcessingInfoState) {
    return videoProcessingInfoState === 'sourceIngression';
  }
  return false;
};

const isVideoProcessing = (video: VideoOrVideoUploadObject) => {
  const videoProcessingInfoState = getVideoProcessingInfoState(video);
  if (videoProcessingInfoState) {
    return videoProcessingInfoState === 'processing';
  }
  return false;
};

const isVideoPartiallyCompleted = (video: VideoUploadByKeyObject) => {
  const videoProcessingInfoState = getVideoProcessingInfoState(video);
  if (videoProcessingInfoState) {
    return videoProcessingInfoState === 'partiallyCompleted';
  }
  return false;
};

const isVideoCompleted = (video: VideoUploadByKeyObject) => {
  const videoProcessingInfoState = getVideoProcessingInfoState(video);
  if (videoProcessingInfoState) {
    return videoProcessingInfoState === 'completed';
  }
  return false;
};

const isVideoUploadQueued = (video: VideoUploadByKeyObject) => {
  const videoUploadingInfoState = getVideoUploadingInfoState(video);
  if (videoUploadingInfoState) {
    return videoUploadingInfoState === 'queued';
  }
  return false;
};

const isVideoUploading = (video: VideoUploadByKeyObject) => {
  const videoUploadingInfoState = getVideoUploadingInfoState(video);
  if (videoUploadingInfoState) {
    return videoUploadingInfoState === 'uploading';
  }
  return false;
};

const isVideoUploadByOther = (video: VideoUploadByKeyObject, currentUser: User) => {
  const currentUserId = _.get(currentUser, 'bytearkAccountUserId', null);
  const creatorId = _.get(video, 'creator.bytearkAccountUserId', null);
  if (creatorId && currentUserId) {
    return creatorId !== currentUserId;
  }
  return false;
};

const isVideoUploadingByOther = (video: VideoUploadByKeyObject, currentUser: User) => {
  const currentUserId = _.get(currentUser, 'bytearkAccountUserId', null);
  const creatorId = _.get(video, 'creator.bytearkAccountUserId', null);
  if (creatorId && currentUserId && isVideoSourceWaiting(video)) {
    return creatorId !== currentUserId;
  }
  return false;
};

const isVideoUploadRetry = (video: VideoUploadByKeyObject) => {
  const videoUploadingInfoState = getVideoUploadingInfoState(video);
  if (videoUploadingInfoState) {
    return videoUploadingInfoState === 'retry';
  }
  return false;
};

const isVideoUploadFailed = (video: VideoUploadByKeyObject) => {
  const videoUploadingInfoState = getVideoUploadingInfoState(video);
  if (videoUploadingInfoState) {
    return videoUploadingInfoState === 'failed';
  }
  return false;
};

const isVideoProbeError = (video: VideoUploadByKeyObject | undefined | null): boolean => (
  !_.isNil(_.get(video, 'vod.source.probeErrorDetail', null))
);

const isVideoProbeErrorFile = (video: VideoOrVideoUploadObject): boolean => {
  const probeErrorDetail = _.get(video, 'vod.source.probeErrorDetail', null);
  const probeErrorType = _.get(video, 'vod.source.probeErrorType', null);
  if (probeErrorDetail && probeErrorType) {
    return _.find(errorCodeForFileProblemCase, (errorCode) => errorCode === probeErrorType) !== undefined;
  }
  return false;
};

const isSourceVideoAvailable = (video: VideoUploadByKeyObject) => {
  if (!isVideoProbeError(video)) {
    const totalExpectedOutputs = _.get(video, 'vod.processingInfo.totalExpectedOutputs', 0);
    return totalExpectedOutputs > 0;
  }
  return false;
  // const hls = _.get(video, 'vod.outputLinks.hls', null);
  // const mp4 = _.get(video, 'vod.outputLinks.mp4', null);
  // return hls || mp4;
};

const isVideoProcessedOverTime = (video: VideoUploadByKeyObject, timeLimit: number) => {
  const now = new Date();
  const dateString = _.get(video, 'uploadingInfo.uploadCompleteTime', null);

  if (!dateString) {
    return false;
  }

  const uploadCompleteTime = new Date(dateString);

  return (now.valueOf() - uploadCompleteTime.valueOf()) > timeLimit;
};

const isVideoProbeCompleted = (video: VideoUploadByKeyObject) => !isVideoProbeNotComplete(video) || isVideoProbeError(video);

const uploadingImage = 'https://i.imgur.com/NhBmeTI.png';

export const getCollectionCoverImage = ({ collection } : {
  collection: Collection | null | undefined;
  defaultCoverImage?: string;
}) => _.get(collection, 'coverImage.sizes', null);

const getVideoUploadCoverImage = ({
  video,
  defaultCoverImage = uploadingImage,
  currentUser,
} : {
  video: Nullable<VideoUploadByKeyObject | Collection>,
  defaultCoverImage?: string,
  currentUser?: Nullable<User>
}) => {
  const coverImage = _.get(video, 'coverImage.sizes', null);
  // uploaded
  if (coverImage) {
    return coverImage;
  }

  // uploading by other user
  if (isVideoUploadByKeyObject(video) && currentUser && isVideoUploadingByOther(video, currentUser) && isVideoSourceWaiting(video)) {
    return defaultCoverImage;
  }

  // use ImageView's default cover image
  return null;
};

const videoProbeDetail = (video: VideoOrVideoUploadObject) => {
  const detailProbeResult = _.get(video, 'vod.source.probeDetail', null);
  if (detailProbeResult) {
    return JSON.stringify(JSON.parse(detailProbeResult), null, '\t');
  }
  return detailProbeResult;
};

const getProbeErrorLog = (video: VideoOrVideoUploadObject) => {
  const probeLog = _.get(video, 'vod.source.probeErrorDetail', null);
  if (probeLog) {
    return JSON.stringify(probeLog, null, '\t');
  }
  return probeLog;
};

const getProbeErrorCode = (video: VideoOrVideoUploadObject) => (
  _.get(video, 'vod.source.probeErrorType', null)
);

const getVideoProcessingInfoStatusMessage = (video: VideoUploadByKeyObject) => {
  if (isVideoSourceWaiting(video)) {
    return i18n.global.t('common:form.isUploadingFiles');
  }

  if (isVideoSourceIngression(video)) {
    if (isVideoProbeError(video)) {
      return i18n.global.t('video.upload.uploadedFileError.title');
    }
    return i18n.global.t('video.upload.checkingFile');
  }

  if (isVideoProcessing(video)) {
    return i18n.global.t('video.upload.processingFile');
  }

  if (isVideoPartiallyCompleted(video)) {
    return i18n.global.t('video.processing.partiallyCompleted');
  }

  if (isVideoCompleted(video)) {
    return i18n.global.t('video.processing.completed');
  }

  return i18n.global.t('common:form.empty');
};

const findCompletedResult = (tracks: VideoJobTrack[]) => {
  if (!tracks) {
    return false;
  }
  return _.find(tracks, (track) => _.find(track.jobs, (job) => (
    job.status === 'completed' ? job : false
  )));
};

const getVideoDeliveryModelTitle = (model: VodDeliveryModel | undefined) => {
  if (!model || model === 'classic') {
    return 'VOD (Classic Model)';
  } if (model === 'model-k') {
    return 'VOD (Adaptive Model 1)';
  }
  return 'VOD (Model-C)';
};

export {
  getVideoCreatorName,
  getVideoDuration,
  getVideoUploadingInfo,
  getVideoUploadingInfoState,
  getVideoProcessingInfo,
  getVideoProcessingInfoState,
  getVideoUploadProgress,
  getVideoUploadCoverImage,
  getVideoTotalExpectedOutputs,
  getVideoTotalCompletedOutputs,
  getVideoProbeErrorType,
  isVideoProbeNotComplete,
  isVideoProject,
  isVideoSourceWaiting,
  isVideoSourceUploading,
  isVideoSourceIngression,
  isVideoProcessing,
  isVideoPartiallyCompleted,
  isVideoCompleted,
  isVideoUploadQueued,
  isVideoUploading,
  isVideoUploadRetry,
  isVideoUploadFailed,
  isVideoUploadByOther,
  isVideoUploadingByOther,
  isSourceVideoAvailable,
  isVideoProbeError,
  isVideoProbeErrorFile,
  isVideoProcessedOverTime,
  isVideoProbeCompleted,
  videoProbeDetail,
  getProbeErrorLog,
  getProbeErrorCode,
  getVideoProcessingInfoStatusMessage,
  findCompletedResult,
  getVideoDeliveryModelTitle,
};
